/* Once we fully migrate from BassCss to Tailwind, we may add the 
base import as below */
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Feijoa';
  src: url('/fonts/feijoa-web-display.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex';
  src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,500&display=swap');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('https://fonts.googleapis.com/css2?family=DM+Sans,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,500;1,700&display=swap');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

h1 {
  @apply text-h1;
  @apply text-gray-900;
  @apply font-serif;
}
h2 {
  @apply text-2xl;
  @apply text-gray-900;
}
h3 {
  @apply text-base;
  @apply font-semibold;
}
div {
  @apply text-gray-900;
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.scrollbar-thin::-webkit-scrollbar {
  width: 4px !important;
}
@media only screen and (max-width: 640px) {
  .hidden-sm {
    display: block;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
  .sm-p-4 {
    padding: 1rem !important;
  }
}
