@use 'sass:map';
@use '@fontsource/ibm-plex-sans/scss/mixins' as IbmPlexSans;
@use '@fontsource/dm-sans/scss/mixins' as DmSans;

/* Include required IBM Plex Sans font weights */
@each $weight in 200, 300, 400, 500, 600, 700 {
  @include IbmPlexSans.fontFace($weight: $weight);
}

/* Specifically require `italic` for the `normal` IBM Plex Sans font weight */
@include IbmPlexSans.fontFace($style: italic, $weight: 400);

@each $weight in 400, 500, 700 {
  @include DmSans.fontFace($weight: $weight);
}

@font-face {
  font-family: 'Feijoa Bold';
  src: url('../public/fonts/feijoa-web-bold.eot');
  src: url('../public/fonts/feijoa-web-bold.woff2') format('woff2'),
    url('../public/fonts/feijoa-web-bold.woff') format('woff');
}

@font-face {
  font-family: 'Feijoa';
  src: url('../public/fonts/feijoa-web-display.eot');
  src: url('../public/fonts/feijoa-web-display.woff2') format('woff2'),
    url('../public/fonts/feijoa-web-display.woff') format('woff');
}

$theme-colors: (
  'success': #14a48a,
  'danger': #d34b46,
  'black': #000000,
  'white': #ffffff,
  'pink': #c66fbf,
  'pink-2': #eddcec,
  'pink-3': #e5c6e3,
  'purple': #8255dd,
  'transparent': transparent,
  'gray': #c4c4c4,
  'blue': #150687,
  'gray-2': #bfbfbf,
  'gray-3': #585757,
  'gray-4': #dbdbdb,
  'gray-5': #737373,
  'gray-6': #f4f4f4,
  'gray-7': #262626,
  'gray-8': #a7a7a7,
  'gray-9': #f2f6f7,
  'gray-10': #d3d3d3,
  'gray-11': #f4f4f4,
  'beige': #bfa27c,
  'dark-green': #025561,
  'gray-green': #e6eced,
  'gray-green-2': #dae6e8,
  'red-2': #cc3340,
  'light-green': rgba(3, 129, 83, 0.2),
  'light-orange': rgba(237, 150, 28, 0.2),
  'orange': #ed961c,
  'green-3': #028079,
  'lemon': #ffca0d,
  'lemon-2': #a07f0a,
);

$gd-axes: (
  'x': 180deg,
  'y': 90deg,
);

$percentages: (
  'none': 0%,
  'tenth': 10%,
  '2-tenths': 20%,
  'quarter': 25%,
  '3-tenths': 30%,
  'third': 33.333%,
  '4-tenths': 40%,
  'half': 50%,
  '6-tenths': 60%,
  '2-thirds': 66.667%,
  '7-tenths': 70%,
  '3-quarters': 75%,
  '8-tenths': 80%,
  '9-tenths': 90%,
  'fill': 100%,
);

$dimensions: (
  'auto': auto,
  'fit-content': fit-content,
  'inherit': inherit,
  'initial': initial,
  'max-content': max-content,
  'min-content': min-content,
  'revert': revert,
  'unset': unset,
  '-webkit-fill-available': -webkit-fill-available,
);

$font-weights: (
  '100': 100,
  '200': 200,
  '300': 300,
  '400': 400,
  '500': 500,
  '600': 600,
  '700': 700,
  '800': 800,
  '900': 900,
  'bold': bold,
  'bolder': bolder,
  'inherit': inherit,
  'initial': initial,
  'lighter': lighter,
  'normal': normal,
  'revert': revert,
  'unset': unset,
);

$border-styles: (
  'dashed': dashed,
  'dotted': dotted,
  'double': double,
  'groove': groove,
  'hidden': hidden,
  'inherit': inherit,
  'initial': initial,
  'inset': inset,
  'none': none,
  'outset': outset,
  'revert': revert,
  'ridge': ridge,
  'solid': solid,
  'unset': unset,
);

$flex-directions: (
  'column': column,
  'column-reverse': column-reverse,
  'inherit': inherit,
  'initial': initial,
  'revert': revert,
  'row': row,
  'row-reverse': row-reverse,
  'unset': unset,
);

$rem-quarters: (
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.5rem,
  '7': 1.75rem,
  '8': 2rem,
);

@each $pc-name, $pc in $percentages {
  .h-#{$pc-name} {
    height: $pc;
  }

  .w-#{$pc-name} {
    width: $pc;
  }
}

@each $dim-name, $dim in $dimensions {
  .h-#{$dim-name} {
    height: $dim;
  }

  .w-#{$dim-name} {
    width: $dim;
  }
}

@each $name-1, $color-code-1 in $theme-colors {
  .c-#{$name-1} {
    color: $color-code-1;
  }

  .bg-#{$name-1} {
    background-color: $color-code-1;
  }

  .fill-#{$name-1} {
    fill: $color-code-1;
  }

  .bc-#{$name-1} {
    border-color: $color-code-1;
  }

  @each $name-2, $color-code-2 in $theme-colors {
    @if $name-1 != $name-2 {
      @each $axis-name, $axis-rotation in $gd-axes {
        .gd-#{$axis-name}-#{$name-1}-#{$name-2} {
          background: linear-gradient(
            map.get($gd-axes, #{$axis-name}),
            map.get($theme-colors, #{$name-1}) 0%,
            map.get($theme-colors, #{$name-2}) 100%
          );
        }
      }
    }
  }
}

@each $fw-name, $fw in $font-weights {
  .fw-#{$fw-name} {
    font-weight: $fw;
  }
}

@each $bs-name, $bs in $border-styles {
  .bs-#{$bs-name} {
    border-style: $bs;
  }
}

@each $fd-name, $fd in $flex-directions {
  .flex-#{$fd-name} {
    flex-direction: $fd;
  }
}

@each $n, $i in $rem-quarters {
  .br-#{$n} {
    border-radius: $i;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
}

html {
  overflow-y: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a[disabled] {
  pointer-events: none;
}

input,
textarea,
.react-datepicker {
  font-family: inherit !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

button {
  cursor: pointer;
  border: 0;
  padding: 0;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.clickable {
  cursor: pointer;
}

.cursor-inherit {
  cursor: inherit;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.no-outline {
  &:focus {
    outline-width: 0;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border: unset;
}

::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 8px;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.nav-bar {
  height: 100px;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-brand {
  width: 10rem;
}

.service-profile-title-1 {
  font-size: 4rem;
  line-height: 1.1;
}

.service-profile-title-2,
.service-profile-owner-name-2 {
  display: none;
}

.service-profile-avatar-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.service-profile-description {
  line-height: 26px;
}

.prospect-form {
  justify-content: flex-start;
  min-height: fit-content;
}

.video-info-container {
  padding-left: 1rem;
  margin-top: -69px;
}

.video-play-button {
  width: 70px;
  height: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-play-button-2 {
  width: 50px;
  height: 50px;
}

.narrowScreen {
  .video-info-container {
    padding-left: 1rem;
    margin-top: -85px;
  }
}

.prospect-form-button {
  width: fit-content;
}

.confirmation-container {
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}

.prospect-page-container {
  height: 100%;
}

.illustration {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;

  &.inverted {
    height: 100%;
    width: auto;
  }
}

.survey-container {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  margin-right: -1rem;
  padding-right: 1rem;
}

.survey-btn {
  width: 10rem;
}

.ws-no-wrap {
  white-space: nowrap;
}

.ws-pre-line {
  white-space: pre-line;
}

.box-shadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.box-shadow-min {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.tooltip {
  display: inline;
  position: relative;
}

.tooltip:hover:after {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #262626;
  border-radius: 2px;
  color: #ffffff;
  content: attr(data-tip);
  top: 180%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.4;
  padding: 4px 8px;
  width: fit-content;
}

.tooltip:hover:before {
  border: solid;
  border-color: #262626 transparent;
  border-width: 0 5px 5px 5px;
  content: '';
  top: 125%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 30px;
  position: absolute;
}

@media (max-width: 767px) {
  .prospect-container {
    flex-direction: column;
    justify-content: space-between;
  }

  .illustration {
    height: 156%;
  }

  .roboto {
    font-family: 'Roboto';
  }

  .nav-bar {
    height: 4rem;
    justify-content: center;
  }

  .nav-brand {
    width: 7rem;
  }

  .service-profile-container {
    flex-direction: column-reverse;
  }

  .service-profile-title-1 {
    display: none;
  }

  .service-profile-owner {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .service-profile-avatar-wrapper {
    width: 100px;
    height: 100px;
    margin-bottom: 2rem;
    border-radius: 20px;
    z-index: 1;
  }

  .service-profile-owner-name {
    display: none;
  }

  .service-profile-owner-name-2 {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }

  .service-profile-title-2 {
    display: block;
    font-size: 32px;
    line-height: 38px;
  }

  .service-profile-description,
  .service-profile-badge-wrapper,
  .service-profile-post-section-title {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .service-profile-form {
    position: fixed;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: 2px solid #dbdbdb;
    z-index: 1;
  }

  .service-profile-form-title {
    margin-bottom: 0;
    margin-top: 12px;
  }

  .service-profile-form-input-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .service-profile-post-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
  }

  .service-profile-mail-link {
    display: none;
  }

  .prospect-form,
  .prospect-page-left-wrapper,
  .prospect-form-button,
  .prospect-page-right-wrapper,
  .survey-container,
  .survey-btn {
    width: 100%;
  }

  .video-info-container {
    padding-left: 0.5rem;
  }

  .video-play-button {
    width: 50px;
    height: 50px;
  }

  .video-play-button-2 {
    width: 40px;
    height: 40px;
  }

  .confirmation-container {
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: left;
  }

  .prospect-page-container {
    height: auto;
    flex-shrink: 0;
    // flex-grow: 1;
  }

  .prospect-information-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .prospect-page-right-wrapper {
    height: 50%;
  }

  .survey-container {
    padding: 0.5rem;
  }

  .testimonial-video {
    width: 42vw;
  }

  .testimonial-text {
    width: 92vw;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .survey-container {
    padding-left: 1rem;
  }

  .service-profile-container {
    flex-direction: row;
    padding: 4rem 1rem;
  }

  .service-profile-section {
    padding-right: 3rem;
    width: 50%;
  }

  .service-profile-video-wrapper {
    width: 50%;
    overflow: hidden;
    border-radius: 30px;
  }

  .service-profile-title-1 {
    font-size: 2.5rem;
  }

  .service-profile-post-section-title {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .service-profile-post-wrapper {
    display: flex;
    max-width: 64rem;
    overflow: auto;
  }

  .service-profile-owner-name {
    font-size: 1.3rem;
  }

  .service-profile-mail-link-2 {
    display: none;
  }

  .prospect-form,
  .prospect-page-left-wrapper {
    width: 100%;
  }

  .prospect-page-left-wrapper {
    &.email-sent {
      text-align: center;
    }
  }

  .prospect-page-right-wrapper {
    &.email-sent {
      height: 50%;
      width: 100%;
    }
    &.email-not-sent {
      height: 100%;
      width: 50%;
    }
  }

  .testimonial-video {
    width: 165px;
  }

  .testimonial-text {
    width: 296px;
  }
}

@media (min-width: 1024px) {
  .service-profile-container {
    flex-direction: row;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .service-profile-section {
    width: 50%;
  }

  .service-profile-copy {
    max-width: 32rem;
  }

  .service-profile-owner-name {
    display: block;
  }

  .service-profile-video-wrapper {
    width: 50%;
    overflow: hidden;
    margin-left: 3rem;
    border-radius: 30px;
  }

  .service-profile-post-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service-profile-mail-link-2 {
    display: none;
  }

  .prospect-form {
    width: 86%;
  }

  .prospect-page-left-wrapper {
    &.email-sent {
      text-align: center;
    }
  }

  .prospect-page-right-wrapper {
    &.email-sent {
      height: 50%;
      width: 100%;
    }
    &.email-not-sent {
      height: 100%;
      width: 50%;
    }
  }

  .testimonial-video {
    width: 165px;
  }

  .testimonial-text {
    width: 296px;
  }
}

.tallScreen {
  .prospect-form {
    justify-content: center;
  }
}

.hovering-toolbar {
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  border-radius: 4px;
  transition: opacity 0.75s;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
}

.hovering-link-editor {
  padding: 3px 8px 3px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  border-radius: 4px;
  margin-top: 60px;
  transition: opacity 0.75s;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
}
$checked-color: #028079;
$unchecked-color: #888;
.slider-checkbox {
  position: relative;
  input {
    margin: 0px;
    margin-top: 1px;
    cursor: pointer;
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    background: red;
    width: 40px;
    height: 20px;
    &:checked + .label {
      &:before {
        background-color: $checked-color;
        content: '';
        padding-left: 6px;
      }
      &:after {
        left: 21px;
      }
    }
  }
}
.label {
  position: relative;
  padding-left: 46px;
  &:before,
  &:after {
    position: absolute;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    transition: background-color 0.3s, left 0.3s;
  }
  &:before {
    content: '';
    color: #fff;
    box-sizing: border-box;
    font-family: 'FontAwesome', sans-serif;
    padding-left: 23px;
    font-size: 12px;
    line-height: 20px;
    background-color: $unchecked-color;
    left: 0px;
    top: 0px;
    height: 20px;
    width: 40px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  &:after {
    content: '';
    letter-spacing: 20px;
    background: #fff;
    left: 1px;
    top: 1px;
    height: 18px;
    width: 18px;
  }
}

/* TODO use tailwind plugin ?? */
.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}
