.rbc-calendar {
  height: calc(100vh - 5rem) !important;
}

.rbc-event {
  background-color: #f4f4f4 !important;
  width: 105% !important;
  left: 0% !important;
  padding: 0 !important;
}

.rbc-header {
  border-bottom: none !important;
  pointer-events: none;
}

.rbc-event-content {
  padding: 0 !important;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none !important;
}

.rbc-day-slot .rbc-event {
  border: none !important;
  color: black !important;
}
.rbc-timeslot-group {
  min-height: 88px !important;
  border-bottom: 2px dotted #ddd !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-event-label {
  display: none !important;
}

.rbc-time-header {
  display: none !important;
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-time-column:last-child {
  border-right: 1px solid #ddd !important;
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-time-content {
  border-top: none !important;
}

.rbc-current-time-indicator {
  background-color: #1f73b7 !important;
}

.custom-date-picker {
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--highlighted {
  background-color: #025561 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: black !important;
}

.react-datepicker__day:hover,
.react-datepicker__day:focus,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--highlighted {
  border-radius: 50% !important;
}

.custom-date-picker {
  .react-datepicker,
  .react-datepicker__header {
    border: none;
    background-color: transparent;
  }

  .react-datepicker__day-name {
    color: #a7a7a7;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2.4rem;
    line-height: 2.4rem;
    margin: 0.5rem;
  }
}

.tablet {
  .custom-date-picker {
    .react-datepicker__day,
    .react-datepicker__day-name {
      width: 2.2rem;
      line-height: 2.2rem;
      margin: 0.4rem;
    }
  }
}
