.Toastify__toast {
  font-family: 'IBM Plex Sans', 'Tahoma', 'sans-serif';
  display: flex;
}

.Toastify__toast-container {
  width: fit-content;
}

.Toastify__toast-body {
  margin-right: 5rem;
}

.Toastify__toast-container--top-center {
  top: -0.3em;
}
.Toastify__toast-container--top-center .Toastify__toast {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__toast-container--bottom-center {
  right: 1rem;
  left: 1rem;
  bottom: 1rem;
  width: 92.5%;
}
.Toastify__toast-container--bottom-center .Toastify__toast {
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
}

.Toastify__toast-container--bottom-right {
  bottom: 1rem;
}
.Toastify__toast-container--bottom-right .Toastify__toast {
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
}

.Toastify__toast--success {
  background: #d1fae5;
}
.Toastify__toast--success .Toastify__toast-body,
.Toastify__toast--success .Toastify__close-button {
  color: #064e3b;
}

.Toastify__toast--error {
  background: #fee2e2;
}
.Toastify__toast--error .Toastify__toast-body,
.Toastify__toast--error .Toastify__close-button {
  color: #991b1b;
}
